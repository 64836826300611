import EnexioLogo from '../../assets/images/png/enexio-logo.png';
import textimg from "../../assets/images/png/textimg.png";
import flashimg from "../../assets/images/png/flash.png";
import waterdropimg from "../../assets/images/png/waterdropimg.png";
import banner1 from "../../assets/images/jpeg/bannerimg1.jpg"
import banner2 from "../../assets/images/jpeg/bannerimg2.jpg"
// import banner3 from "../../assets/images/jpeg/bannerimg3.jpg"
// import banner4 from "../../assets/images/jpeg/bannerimg4.jpg"
import banner5 from "../../assets/images/jpeg/bannerimg5.jpg"
import banner6 from "../../assets/images/jpeg/homeBanner6.jpg"
import banner7 from "../../assets/images/jpeg/homeBanner7.jpg"
import banner8 from "../../assets/images/jpeg/homeBanner8.jpg"
import banner9 from "../../assets/images/jpeg/homeBanner9.jpg"
import linkedin from "../../assets/images/svg/social-linkedin.svg"
import whowearebanner from "../../assets/images/jpeg/whoweareBanner.jpg"
import companiesbanner from "../../assets/images/jpeg/companiesbanner.jpg"
import newsbanner from "../../assets/images/jpeg/newsBanner.jpg";
import exhibitionBanner from "../../assets/images/jpeg/ExhibitionBanner.jpg";
import GeneralPurchasebanner from "../../assets/images/jpeg/generalpurchaseBanner.jpg";
import GovernanceBanner from "../../assets/images/jpeg/governanceBanner.jpg";
import QualityHseBanner from "../../assets/images/jpeg/qualityHseBanner.jpg";
import H20MinwaterBanner from "../../assets/images/jpeg/h20MinwaterBanner.jpg";
import HistoryBanner from "../../assets/images/jpeg/historyBanner.jpg";
import VacanciesBanner from "../../assets/images/jpeg/vaccanciesBanner.jpg";
import productimg1 from "../../assets/images/png/productimg1.png"
import productimg2 from "../../assets/images/png/productimg2.png"
import productimg3 from "../../assets/images/png/productimg3.png"
import CeoImg from "../../assets/images/jpeg/ceoImg.jpg";
import Error404 from "../../assets/images/jpeg/404errorimg.jpg";
import Construction from "../../assets/images/png/construction.png";
import recentlyPusblished from "../../assets/images/png/recentlypublished.png";
import WetCoolingTowers from "../../assets/images/jpeg/wetcoolingsystem.jpg"
import Rcc2 from "../../assets/images/jpeg/rcc2.jpg"
import RccTowers from "../../assets/images/jpeg/rccTowers.jpg"
import FrpTowers from "../../assets/images/jpeg/frpTowers.jpg"
import Drift from "../../assets/images/jpeg/drift.jpg"
import FilmFill from "../../assets/images/jpeg/filmFill.jpg"
import NF from "../../assets/images/jpeg/NF.jpg"
import SprayNozzle from "../../assets/images/png/SprayNozzle.png"
import SprayNozzle2 from "../../assets/images/png/SprayNozzle2.png"
import AirCooled from "../../assets/images/jpeg/airCooled.png"
import AirCooledImg1 from "../../assets/images/jpeg/airCooledimg1.jpg"
import AirCooledImg2 from "../../assets/images/jpeg/airCooledimg2.jpg"
import AirCooledImg3 from "../../assets/images/jpeg/airCooledimg3.png"
import AirCooledImg4 from "../../assets/images/jpeg/airCooledimg4.png"
import AirCooledRebundling from "../../assets/images/jpeg/aircooledRebundling.png"
import CombinedDryandWet from "../../assets/images/jpeg/combinedDryandWet.jpg"
import DryWetCooling1 from "../../assets/images/jpeg/dryWetCooling1.jpg"
import DryWetCooling2 from "../../assets/images/jpeg/dryWetCooling2.jpg"
import DryWetCooling3 from "../../assets/images/jpeg/dryWetCooling3.jpg"
import DryWetCooling4 from "../../assets/images/jpeg/dryWetCooling4.jpg"
import Header_Contact from "../../assets/images/png/Header_Contact.jpg";
import ENX_Header from "../../assets/images/png/ENX_Header.jpg";
import ENEXIO_I from "../../assets/images/png/ENEXIO_I.jpg";
import acc from "../../assets/images/png/acc.png";
import ACC from "../../assets/images/jpeg/ACC.jpg";
import serviceBanner from "../../assets/images/png/servicesBanner.png";
import ccpg from "../../assets/images/jpeg/ccpg.jpg";
import ENEXIO_QMHSE_Policy from "../../assets/images/jpeg/ENEXIO_QMHSE_Policy.pdf";
import wcc from "../../assets/images/jpeg/wcc.jpg";
import Economy from "../../assets/images/png/Economy.jpg";
import inair from "../../assets/images/png/inair.png";
import rndair from "../../assets/images/png/rndair.png";
import vcp from "../../assets/images/jpeg/vcp.jpg";
import Werdohl_Mark from "../../assets/images/png/Werdohl_Mark.jpg";
import AlexReference from "../../assets/images/jpeg/AlexReference.jpeg";
import brkp from "../../assets/images/png/brkp.png";
import spd from "../../assets/images/png/spd.png";
import managingDir from "../../assets/images/jpeg/managing-dir.jpg";
import director from "../../assets/images/jpeg/director.jpg";
import presidentImg from "../../assets/images/jpeg/presidentImg.jpg";
import executive from "../../assets/images/jpeg/executive.jpg";
import AlexTubeBundle from "../../assets/images/jpeg/AlexTubeBundle.jpg";
import Inair1 from "../../assets/images/jpeg/inair/Inair1.jpg";
import Inair2 from "../../assets/images/jpeg/inair/Inair2.jpg";
import Inair3 from "../../assets/images/jpeg/inair/Inair3.jpg";
import Inair4 from "../../assets/images/jpeg/inair/Inair4.jpg";
import Inair5 from "../../assets/images/jpeg/inair/Inair5.jpg";
import Inair6 from "../../assets/images/jpeg/inair/Inair6.jpg";
import Inair7 from "../../assets/images/jpeg/inair/Inair7.jpg";
import Inair8 from "../../assets/images/jpeg/inair/Inair8.jpg";
import Inair9 from "../../assets/images/jpeg/inair/Inair9.jpg";
import Inair10 from "../../assets/images/jpeg/inair/Inair10.jpg";
import Inair15 from "../../assets/images/jpeg/inair/Inair15.jpg";


import inair_pageImg from "../../assets/images/jpeg/inair_pageImg.jpg"
import inair_reference_world_map from "../../assets/images/jpeg/inair_reference_world_map.jpg"
import inair_pageImg2 from "../../assets/images/jpeg/inair_pageImg2.jpg"
import classic_acc from "../../assets/images/jpeg/classic_acc.png"
import Classic1 from "../../assets/images/jpeg/classic/Classic1.png";
import Classic2 from "../../assets/images/jpeg/classic/Classic2.jpg";
import Classic3 from "../../assets/images/jpeg/classic/Classic3.jpg";
import Classic4 from "../../assets/images/jpeg/classic/Classic4.jpg";
import Classic5 from "../../assets/images/jpeg/classic/Classic5.jpg";
import Classic6 from "../../assets/images/jpeg/classic/Classic6.jpg";
import Classic7 from "../../assets/images/jpeg/classic/Classic7.jpg";
import Classic8 from "../../assets/images/jpeg/classic/Classic8.jpg";
import Classic9 from "../../assets/images/jpeg/classic/Classic9.jpg";
import Classic10 from "../../assets/images/jpeg/classic/Classic10.jpg";
import Classic11 from "../../assets/images/jpeg/classic/Classic11.jpg";
import Classic from "../../assets/images/jpeg/classic/Classic.jpg";
import classicAcc_pageImg from "../../assets/images/jpeg/classicAcc_pageImg.jpg";
import aboutUsBanner from "../../assets/images/jpeg/aboutUsBanner.jpg"
import ndct1 from "../../assets/images/jpeg/NDCT/ndct1.jpg";
import ndct2 from "../../assets/images/jpeg/NDCT/ndct2.jpg";
import ndct3 from "../../assets/images/jpeg/NDCT/ndct3.jpg";
import ndct4 from "../../assets/images/jpeg/NDCT/ndct4.jpg";
import ndct5 from "../../assets/images/jpeg/NDCT/ndct5.jpg";
import ndct6 from "../../assets/images/jpeg/NDCT/ndct6.jpg";


import idct1 from "../../assets/images/jpeg/IDCT/idct1.jpg"
import idct2 from "../../assets/images/jpeg/IDCT/idct2.jpg"
import idct3 from "../../assets/images/jpeg/IDCT/idct3.jpg"
import idct4 from "../../assets/images/jpeg/IDCT/idct4.jpg"
import idct5 from "../../assets/images/jpeg/IDCT/idct5.jpg"
import idct6 from "../../assets/images/jpeg/IDCT/idct6.jpg"

import IDCTbanner from "../../assets/images/jpeg/IDCTbanner.png";
import ndct from "../../assets/images/jpeg/ndct.png"
// import bannerimg1_ from "../../assets/images/jpeg/bannerimg1_.png"
import afterslaesServices1 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices1.jpg"
import afterslaesServices2 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices2.jpg"
import afterslaesServices3 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices3.jpg"
import afterslaesServices4 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices4.jpg"
import afterslaesServices5 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices5.jpg"
import afterslaesServices6 from "../../assets/images/jpeg/AfterSalesServices/afterslaesServices6.jpg"

import referencesBanner from "../../assets/images/jpeg/referencesBanner.jpg"
import CoolingTowerlogo from "../../assets/images/svg/CoolingTowerlogo.svg"
import Servicelogo from "../../assets/images/svg/Servicelogo.svg"
import hybridAcc from "../../assets/images/jpeg/hybridAcc.jpg"
import hybridRefImg from "../../assets/images/jpeg/hybridRefImg.jpg"
import hybridRefImg2 from "../../assets/images/jpeg/hybridRefImg2.jpg"
import hybridRefImg3 from "../../assets/images/jpeg/hybridRefImg3.jpg"
import hybridRefImg4 from "../../assets/images/jpeg/hybridRefImg4.jpg"
import hybridRefImg5 from "../../assets/images/jpeg/hybridRefImg5.jpg"
import Classic_Acc_banner from "../../assets/images/jpeg/Classic_Acc_banner.jpg"
import CT_Components1 from "../../assets/images/jpeg/ctComponents/CT_Components1.jpg"
import CT_Components2 from "../../assets/images/jpeg/ctComponents/CT_Components2.jpg"
import CT_Components3 from "../../assets/images/jpeg/ctComponents/CT_Components3.jpg"
import CT_Components4 from "../../assets/images/jpeg/ctComponents/CT_Components4.jpg"
import wetcoolingsystem1 from "../../assets/images/svg/wetcoolingsystem1.svg"
import wetcoolingsystem2 from "../../assets/images/svg/wetcoolingsystem2.svg"
import alexSystem_pageImg1 from "../../assets/images/jpeg/alexSystem/alexSystem_pageImg1.jpg"
import alexSystem_pageImg2 from "../../assets/images/jpeg/alexSystem/alexSystem_pageImg2.jpg"
import alexSystem_pageImg3 from "../../assets/images/jpeg/alexSystem/alexSystem_pageImg3.jpg"
import alexSystem_pageImg4 from "../../assets/images/jpeg/alexSystem/alexSystem_pageImg4.jpg"
import alexSystem_pageImg5 from "../../assets/images/jpeg/alexSystem/alexSystem_pageImg5.jpg"
import corporateGovContactImg from "../../assets/images/png/corporateGovContactImg.png"
import inairLogo from "../../assets/images/jpeg/inairLogo.jpg"

import Invitation from '../../assets/images/jpeg/Enexio-Invitation.jpg'
import E from 'react-script';

const Images = {
    EnexioLogo: EnexioLogo,
    textimg: textimg,
    flashimg: flashimg,
    waterdropimg: waterdropimg,
    banner1: banner1,
    banner2: banner2,
    // banner3: banner3,
    // banner4: banner4,
    banner5: banner5,
    banner6: banner6,
    banner7: banner7,
    banner8: banner8,
    banner9: banner9,

    linkedin: linkedin,
    whowearebanner: whowearebanner,
    companiesbanner: companiesbanner,
    productimg1: productimg1,
    productimg2: productimg2,
    productimg3: productimg3,
    CeoImg: CeoImg,
    Error404: Error404,
    Construction: Construction,
    recentlyPusblished: recentlyPusblished,
    newsbanner: newsbanner,
    exhibitionBanner: exhibitionBanner,
    GovernanceBanner: GovernanceBanner,
    GeneralPurchasebanner: GeneralPurchasebanner,
    QualityHseBanner: QualityHseBanner,
    H20MinwaterBanner: H20MinwaterBanner,
    HistoryBanner: HistoryBanner,
    VacanciesBanner: VacanciesBanner,
    // WetCoolingTowers: WetCoolingTowers,
    RccTowers: RccTowers,
    Rcc2: Rcc2,
    FrpTowers: FrpTowers,
    Drift: Drift,
    FilmFill: FilmFill,
    NF: NF,
    SprayNozzle: SprayNozzle,
    SprayNozzle2: SprayNozzle2,

    AirCooled: AirCooled,
    AirCooledImg1: AirCooledImg1,
    AirCooledImg2: AirCooledImg2,
    AirCooledImg3: AirCooledImg3,
    AirCooledImg4: AirCooledImg4,
    AirCooledRebundling: AirCooledRebundling,
    CombinedDryandWet: CombinedDryandWet,
    DryWetCooling1: DryWetCooling1,
    DryWetCooling2: DryWetCooling2,
    DryWetCooling3: DryWetCooling3,
    DryWetCooling4: DryWetCooling4,
    Header_Contact: Header_Contact,
    ENX_Header: ENX_Header,
    ENEXIO_I: ENEXIO_I,
    acc: acc,
    ccpg: ccpg,
    ENEXIO_QMHSE_Policy: ENEXIO_QMHSE_Policy,
    wcc: wcc,
    Economy: Economy,
    rndair: rndair,
    inair: inair,
    vcp: vcp,
    Werdohl_Mark: Werdohl_Mark,
    AlexReference: AlexReference,
    brkp: brkp,
    spd: spd,
    managingDir: managingDir,
    Director: director,
    presidentImg: presidentImg,
    executive: executive,
    AlexTubeBundle: AlexTubeBundle,
    Inair1: Inair1,
    Inair2: Inair2,
    Inair3: Inair3,
    Inair4: Inair4,
    Inair5: Inair5,
    Inair6: Inair6,
    Inair7: Inair7,
    Inair8: Inair8,
    Inair9: Inair9,
    Inair10: Inair10,
    Inair15: Inair15,

    inair_pageImg: inair_pageImg,
    inair_reference_world_map: inair_reference_world_map,
    inair_pageImg2: inair_pageImg2,
    classic_acc: classic_acc,
    Classic1: Classic1,
    Classic2: Classic2,
    Classic3: Classic3,
    Classic4: Classic4,
    Classic5: Classic5,
    Classic6: Classic6,
    Classic7: Classic7,
    Classic8: Classic8,
    Classic9: Classic9,
    Classic10: Classic10,
    Classic11: Classic11,
    Classic: Classic,
    classicAcc_pageImg: classicAcc_pageImg,
    ndct: ndct,
    ndct1: ndct1,
    ndct2: ndct2,
    ndct3: ndct3,
    ndct4: ndct4,
    ndct5: ndct5,
    ndct6: ndct6,


    idct1: idct1,
    idct2: idct2,
    idct3: idct3,
    idct4: idct4,
    idct5: idct5,
    idct6: idct6,

    IDCTbanner: IDCTbanner,
    serviceBanner: serviceBanner,
    // bannerimg1_: bannerimg1_,
    ACC: ACC,
    afterslaesServices1: afterslaesServices1,
    afterslaesServices2: afterslaesServices2,
    afterslaesServices3: afterslaesServices3,
    afterslaesServices4: afterslaesServices4,
    afterslaesServices5: afterslaesServices5,
    afterslaesServices6: afterslaesServices6,

    aboutUsBanner: aboutUsBanner,
    referencesBanner: referencesBanner,
    Servicelogo: Servicelogo,
    CoolingTowerlogo: CoolingTowerlogo,
    hybridAcc: hybridAcc,
    hybridRefImg: hybridRefImg,
    hybridRefImg2: hybridRefImg2,
    hybridRefImg3: hybridRefImg3,
    hybridRefImg4: hybridRefImg4,
    hybridRefImg5: hybridRefImg5,
    Classic_Acc_banner: Classic_Acc_banner,
    CT_Components1: CT_Components1,
    CT_Components2: CT_Components2,
    CT_Components3: CT_Components3,
    CT_Components4: CT_Components4,
    WetCoolingTowers: WetCoolingTowers,
    wetcoolingsystem1: wetcoolingsystem1,
    wetcoolingsystem2: wetcoolingsystem2,
    alexSystem_pageImg1: alexSystem_pageImg1,
    alexSystem_pageImg2: alexSystem_pageImg2,
    alexSystem_pageImg3: alexSystem_pageImg3,
    alexSystem_pageImg4: alexSystem_pageImg4,
    alexSystem_pageImg5: alexSystem_pageImg5,
    corporateGovContactImg: corporateGovContactImg,
    inairLogo: inairLogo,
    Expo: Invitation,

}
export default Images