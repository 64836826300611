import React from 'react'
import Accordion from '../../commonComponents/Accordion/Accordion'
import Images from '../../commonComponents/Images/Images'

function OurManagement() {
    const AccordionData = [
        {
            id: 1,
            label: 'Accordian10',
            header: 'CHAIRMAN & MANAGING DIRECTOR',
            content: <> <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                    <img src={Images.managingDir} />
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <p className='accordion-text'>Ms Swarnamugi Raghupathy is a seasoned business leader and visionary strategist, currently serving as the Chairman of Enexio Power Cooling Solutions India Pvt Ltd, a distinguished company renowned for its excellence in Power Cooling Solutions. An Engineer and a Post Graduate Diploma in embedded systems. With a career spanning 19 years, Ms Swarnamugi brings a wealth of experience and expertise to the helm of the organization. She is also the Founder and Director of BGR NEO Limited LTD a robotic and Automation solutions for varied MNC and other FMCG sectors across India. </p>
                    <p className='accordion-text'>    A stalwart in the industry, Ms Swarnamugi has a proven track record of driving growth, fostering innovation, and delivering value. Her strategic foresight and unwavering commitment to excellence have been instrumental in steering Enexio Power Cooling Solutions India Pvt Ltd through dynamic market landscapes and positioning it as a formidable player in the global marketplace. </p>
                    <p className='accordion-text'>Prior to assuming the role of Chairman, Ms Swarnamugi held key leadership position as Managing director within Enexio Power Cooling Solutions India Pvt Ltd and other esteemed organizations, where she demonstrated exceptional leadership skills and a keen understanding of market dynamics. Her leadership style, characterized by integrity, transparency, and a people-centric approach, has earned them the respect and admiration of colleagues and industry peers alike. </p>
                    {/* <p className='accordion-text'>
                        Under Ms Swarnamugi’s stewardship, Enexio Power Cooling Solutions India Pvt Ltd has achieved numerous milestones. Her strategic initiatives have propelled the company to new heights of success, cementing its reputation as an industry leader and driving sustainable growth in the face of evolving challenges. </p>
                    <p className='accordion-text'>
                        Beyond her professional achievements, Ms Swarnamugi is actively involved in philanthropic endeavours and corporate social responsibility initiatives, reflecting their commitment to making a positive impact on society. </p>
                    <p className='accordion-text'>
                        In summary, Ms Swarnamugi epitomizes leadership excellence and inspires confidence in stakeholders through their visionary leadership, strategic acumen, and unwavering dedication to advancing Enexio Power Cooling Solutions India Pvt Ltd.’s mission and values.
                    </p> */}
                </div>
            </div></>
        },
        {
            id: 2,
            label: 'Accordian9',
            header: 'DIRECTOR ',
            content: <> <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                    <img src={Images.Director   } />
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <p className='accordion-text'>
                    Mr. Parthiban A.P. is a distinguished professional with over 25 years of extensive experience in the infrastructure and construction industry. He holds a postgraduate degree in finance and management and has successfully overseen the development of more than 2.5 million square feet of infrastructure in Chennai as a Managing Director. His expertise spans IoT platform integration, cross-sector innovation, and strategic business analysis. Mr. Parthiban A.P. is a dedicated entrepreneur, currently serving as the Director at Enexio Power Cooling Solutions India Private Limited, Director at BGR NEO Limited, Director at Swadhika Foods LLP, Managing Director of Alphafour Infrastructure Pvt Ltd and Finance Director at Nanoax making significant contributions to fintech, infrastructure, robotics, food processing and automation sectors globally.</p>
                   <div className="accordion-text">
                        <strong>Professional Experience</strong>

                       <ul>
                            <li><strong>Director, Enexio Power Cooling Solutions India Private Limited</strong><br />
                            ENEXIO Power Cooling Solutions India Pvt Ltd is steered by Mr. Parthiban A.P., a seasoned expert in the power cooling industry. As Director, Mr. Parthiban A.P. is dedicated to driving innovation, upholding operational excellence, and delivering superior solutions to meet the changing demands of clients. With a strong focus on customer satisfaction, he oversees both the strategic direction and daily operations of the company, ensuring that ENEXIO remains at the forefront of the cooling solutions market.</li>        

                            <li><strong>Director, BGR NEO Limited</strong><br />
                            Mr Parthiban A.P. also serves as a Director at BGR NEO Limited, a company specializing in robotics and automation solutions for the FMCG sector worldwide. His role involves guiding the strategic direction and operational excellence of BGR NEO Limited, contributing to its global expansion and innovation in the field of robotics.</li>

                            <li> <strong className=''>Director, Swadhika Foods LLP</strong> <br />
                            Swadhika Foods LLP is steered by Mr. Parthiban A.P., a visionary Director dedicated to preserving the natural taste and health benefits of food. With decades of experience, Mr. Parthiban A.P. emphasizes innovation, quality, and freshness, ensuring that all products are free from preservatives and synthetic colors. His mission is to establish a global benchmark in product excellence and build trust-based partnerships with clients. By focusing on customer needs and delivering the right product at the right price, Swadhika Foods LLP has successfully expanded its international presence, all while upholding core values of responsibility, pride, and sustainability.</li>                            

                            <li> <strong className=''>Managing Director, Alpha Four Infrastructure Pvt Ltd</strong> <br />
                            As the Managing Director of Alpha Four Infrastructure Pvt Ltd, Mr Parthiban A.P. has led the development of over 2.5 million square feet of infrastructure in Chennai. His exceptional project management skills and strategic vision have ensured the successful completion of large-scale projects, significantly enhancing the region's infrastructure landscape.</li>
                       
                            <li><strong>Finance Director, Nanoax</strong><br />
                            In his current role as Finance Director at Nanoax, Mr. Parthiban A.P. oversees financial strategies and operations for the company. Nanoax specializes in low-code/no-code technology solutions catering to the fintech sector globally. His financial acumen and leadership have been instrumental in driving Nanoax's growth and establishing it as a global fintech leader.</li>

                            <li><strong>IoT Platform Expertise with STROBS USA</strong><br />
                            Mr Parthiban A.P. has deep expertise in the Internet of Things (IoT), working with STROBS USA to introduce niche IoT products across various sectors, including life sciences, hospitality, and education. His innovative solutions have been implemented in Texas, USA, China, India, and Sri Lanka, significantly improving operational efficiencies and opening new business opportunities across these platforms.</li>


                            <li><strong>Entrepreneurial Ventures</strong><br />
                            A dedicated entrepreneur, Mr. Parthiban A.P. has served as a business analyst for numerous startups. His strategic insights and comprehensive business analysis have helped many emerging businesses overcome challenges and achieve sustainable growth. His commitment to innovation and entrepreneurship has made him a sought-after advisor in the startup community.</li>
                        </ul>
                        <strong>Areas of Expertise</strong>
                        <ul>
                            <li> Infrastructure and Construction Management: Proven track record in managing and delivering large-scale infrastructure projects, including the development of over 2.5 million square feet in Chennai.</li>
                            <li> IoT Integration with STROBS USA: Expertise in implementing IoT-based niche products across diverse sectors such as life sciences, hospitality, and education in multiple countries.</li>
                            <li> Startup Consultancy: Skilled in providing strategic business analysis and support to startups, fostering their growth and sustainability.</li>
                            <li> Financial Leadership: Experienced in formulating and executing financial strategies as the Finance Director at Nanoax, a global fintech innovator.</li>
                            <li> Robotics and Automation: Strategic leadership in robotics and automation solutions for the FMCG sector as a Director at BGR NEO Limited.</li>
                        </ul>
                        <strong>Educational Background</strong>
                        <ul>
                        Mr Parthiban A.P. holds a postgraduate degree in Finance and Management, which has provided him with a strong foundation to excel in various business environments and leadership roles.
                        </ul>
                        <strong> Personal Attributes</strong>
                        <ul>
                        Mr Parthiban A.P. is recognized for his entrepreneurial mindset and strategic thinking. His dedication to innovation and excellence has made him a respected figure in the infrastructure, technology, fintech, and robotics sectors. His ability to integrate technical expertise with visionary leadership continues to inspire those around him, driving significant advancements in his fields of expertise.
                        </ul>   
                    </div>
                </div>
            </div></>
        },
        {
            id: 3,
            label: 'Accordian11',
            header: 'EXECUTIVE DIRECTOR ',
            content: <>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                        <img src={Images.presidentImg} />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12' >
                        <p className='accordion-text'>Mr. T. Parasuram has been President of ENEXIO Power Cooling Solutions India Pvt. Ltd. Since January 2008. He held leading positions in the Manufacturing, Maintenance and Project Management in the AFC division of BGR Energy between 1995 to 2006. Before joining BGR groups he had been in a leading petrochemical company, Tamil Nadu Petro products Ltd. He has been involved in setting up several of the manufacturing units of ENEXIO India & BGR- AFC. Mr. T. Parasuram has a Mechanical Engineering Degree from Manipal Institute of Technology. </p>
                    </div>
                </div>
            </>
        },
//         {
//             id: 4,
//             label: 'Accordian12',
//             header: 'ADDITIONAL NON-EXECUTIVE DIRECTOR ',
//             content: <>
//                 <div className='row'>
//                     <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

//                         <img src={Images.executive} />
//                     </div>
//                     <div className='col-lg-8 col-md-8 col-sm-12' >
//                         <p className='accordion-text'>Mr. K. Gnanadesikan is a distinguished retired Indian Administrative Service (IAS) officer with over 30 years of experience in public administration and governance. He has held several prominent leadership positions, including Chief Secretary of Tamil Nadu, Chairman of TIDCO, and CMD of TNEB/TANGEDCO. His career has been marked by his expertise in finance, electricity, industries, and governance, contributing significantly to the development of Tamil Nadu’s administrative and economic frameworks.

 

// As an accomplished administrator, Mr. Gnanadesikan has played a key role in overseeing large-scale infrastructure projects, navigating complex regulatory environments, and implementing strategic initiatives that have had a lasting impact on the state’s growth and development. His profound knowledge and leadership skills will add significant value to Enexio as the company continues its journey of transformation and innovation. </p>
//                     </div>
//                 </div>
//             </>
//         },

    ]

    return (
        <div className='ourmanagement-main'>
            <div className='heading-text'>
                <h1>OUR MANAGEMENT TEAM</h1>
                <h2 className='ice-blue'>Success is a matter of a good team</h2>
                <p><span className="lead">Our management team executes strategies to adapt our solutions and services to the individual needs of our customers and other business partners worldwide:</span></p>
                <ul><li>We act with <strong>integrity</strong> and <strong>professionalism</strong></li><li>We deliver <strong>excellence</strong> around the world through an <strong>integrated global team</strong></li><li>We <strong>innovate</strong> continuously</li><li>We are <strong>align</strong> with <strong>customer needs</strong> and <strong>expectations&nbsp;</strong></li></ul>
            </div>
            <div className='accordian-section'>
                <Accordion AccoridanDataProps={AccordionData} />
            </div>
        </div>
    )
}

export default OurManagement